import { 
    faChartSimple, 
    faHospitalUser, 
    faDollarSign, 
    faFileWaveform, 
    faGear, faCalendarCheck, faThunderstorm,
    faPills,
    faSquarePlus,
    faUser,
    faHandHoldingMedical,
    faMoneyBill1Wave,
    faListCheck,
    faBed,
    faHourglass1,
    faForward,
    faBedPulse,
    faPersonBooth,
    faList,
    faDisease,
    faCoins,
    faFileContract
} from '@fortawesome/free-solid-svg-icons'

export const SIDEBAR_MENU = {
    RC: [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "report", name:"Reports", path:"/reports", icon: faFileWaveform },
        {id: "addmissions", name:"Addmissions", path:"/addmissions", icon: faBedPulse },
        {id: "rooms", name:"Rooms", path:"/rooms", icon: faPersonBooth },
        {id: "nextvisit", name:"Next Visit", path:"/next-visit", icon: faForward },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
    ],

    SA: [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "users", name:"Users", path:"/users", icon: faUser },
        {id: "services", name:"Services", path:"/services", icon: faHandHoldingMedical },
        {id: "accountant", name:"Accountant", path:"/accountant", icon: faHandHoldingMedical, isDropdown : true, children:[
          {id:"expense", name:'Expense', path:"/accountant/expense", icon : faMoneyBill1Wave},
          {id:"Income", name:'Expense', path:"/accountant/income", icon : faMoneyBill1Wave},
        ] },

        {id: "rooms", name:"Rooms", path:"/rooms", icon: faPersonBooth },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        // {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },

    ],

    DR : [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "tokens", name:"Tokens", path:"/tokens", icon: faCoins },

        {id: "appointments", name:"Appointments", path:"/appointments", icon: faFileContract },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        
        {id: "ipdpatients", name:"IPD Patients", path:"/ipd-patients", icon: faBed },
        {id: "schedule", name:"Dosing Schedule", path:"/dosing-schedules", icon: faHourglass1 },

        {id: "disease", name:"Diseases", path:"/add-disease", icon: faDisease },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "chiefofcomplaint", name:"Chief of Complaint", path:"/chief-of-complaints", icon: faList },

        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        // {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    JDR : [
        {id: "currentappointments", name:"Tokens", path:"/", icon: faCoins },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faFileContract },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        {id: "ipdpatients", name:"IPD Patients", path:"/ipd-patients", icon: faBed },
        {id: "schedule", name:"Dosing Schedule", path:"/dosing-schedules", icon: faHourglass1 },
        {id: "disease", name:"Diseases", path:"/add-disease", icon: faDisease },
        {id: "chiefofcomplaint", name:"Chief of Complaint", path:"/chief-of-complaints", icon: faList },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },


        // {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    PH : [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "medicines", name:"Medicines", path:"/medicines", icon: faPills },

        {id: "add-medicine", name:"Add Medicine", path:"/add-medicine", icon:faSquarePlus  },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        // {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    AC : [
      {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
      {id: "expenses", name:"Expenses", path:"/expenses", icon: faDollarSign },
      {id: "earning", name:"Earning", path:"/earning", icon: faMoneyBill1Wave },
      {id: "setup", name:"Setup", path:"/setup", icon: faListCheck },
      {id: "setting", name:"Setting", path:"/settings", icon: faGear },
    ],

    IPD :[
      {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
      {id: "patients", name:"Patients", path:"/patients", icon: faBed },
      {id: "schedule", name:"Dosing Schedule", path:"/dosing-schedules", icon: faHourglass1 },
      {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    NR : [
      {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
      {id: "patients", name:"Patients", path:"/patients", icon: faBed },
      {id: "schedule", name:"Dosing Schedule", path:"/dosing-schedules", icon: faHourglass1 },

      // {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    LB : [
      {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
      {id: "reports", name:"Reports", path:"/reports", icon: faBed },
    ]

}