import Avatar from '../assets/images/avatar.jpg';
import Logo from '../assets/images/logo.png';
import LoginBanner from '../assets/images/login-banner.png';
import { faFilePdf, faFileCsv, faImage } from '@fortawesome/free-solid-svg-icons'

export const avatar =  Avatar
export const logo = Logo
export const login_banner = LoginBanner


export const ROLES = {
  "SA": "Super Admin",
  "PT": "Patient",
  "AC": "Accountant",
  "RC": "Reception",
  "DR": "Doctor",
  "JDR": "Junior Doctor",
  "PH": "Pharmacist",
  "IPD": "IPD",
  "NR": "Nurse",
  "LB": "Lab"
}


export const medicineTypes = [
    {label:"Injection", value:"injection", color: "red" },
    {label:"Tablet", value:"tablet", color: "green" },
    {label:"Capsule", value:"capsule", color: "pink" },
    {label:"Syrup", value:"syrup", color: "brown" },
    {label:"Cream", value:"cream" , color: "violet"},
    {label:"Lotion", value:"lotion", color: "yellow" },
    {label:"Powder", value:"powder", color: "grey" },
    {label:"Other", value:"other", color: "blue" },
]

export const paymentModes = [
    { label: "Cash", value: 'cash' }, 
    { label: "Online", value: "online" },
    { label: "FOC", value: "foc" },
    { label: "Follow Up", value: "followUp" },
]

export const medicineStatus = [
    { label: "In Stock", value: "inStock" },
    { label: "Low Stock", value: "lowStock" },
    { label: "Out of Stock", value: "outOfStock" },
    { label: "Expired", value: "expired" },
  ]



export const firms = [
  "Narayan Medical Care",
  "Shubh SuperSpeciality Center"
]

export const reportsType = [
  "CT Scan",
  "MRI",
  "Fibre Scan",
  "Blood Test"
]

export const  serviceTypes =[ "OPD", "Lab" ]

export const doseTimming = [ "BD", "OD", "BBF", "TDS", "QID", "HS", "SOS" ]

export const doseRoute = [ 'IV', 'IM',"SC", 'P/O', "QR" ]

export const INVESTIGATIONS = ['VSG WHOLE','VSG ABD', 'HBV-DNA', 'HCV-RNA','CBC', 'LFT', 'KFT', 'S.LIPASE', 'HBSAG/ANTI HVC', 'TSH','CECT', 'WHOLE ABD', 'COLONSCOPY', 'SIGMAIDOSCOPY', 'ENDOSCOPY', 'ERCP', 'FORIEGN BODY REMOVEL', 'EVL', 'GLUE INJESTION' ]
  
export const FILE_ICONS = {
  "csv": faFileCsv,
  "pdf": faFilePdf,
  "image": faImage,
  "png": faImage,
  "jpg": faImage,
  "jpeg": faImage,
}
  
  